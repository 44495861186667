import React from 'react';
import { useLocation  } from 'react-router-dom';

import { useWheel } from './use-wheel';

import overlay from './overlay.svg';
const overlayImage = new Image();
overlayImage.src = overlay;

const Roleta = () => {
  const [items, setItems] = React.useState([]);
  const [config, setConfig] = React.useState({});
  const [debugMode, setDebugMode] = React.useState(false);

  const location = useLocation();
  const phone = new URLSearchParams(location.search).get('phone');

  const { wheel, wheelComponent } = useWheel({ initialProps: {} });

  const redrawWheel = () => {
    for (const key in config) {
      if (key === 'overlayImage') {
        wheel[key] = overlayImage;
      } else {
        wheel[key] = config[key];
      }
    }
    wheel.items = items;
  };

  React.useEffect(() => {
    fetch('https://api.client.dawntech.dev/blipid2024/roleta/configuration').then(r => r.json()).then((response) => {
      const config = JSON.parse(response.config);
      config.overlayImage = overlayImage;
      setConfig(config);
      setItems(JSON.parse(response.items));
    });
  }, []);

  React.useEffect(() => {
    if (Object.keys(config).length > 0 && items?.length > 0) {
      redrawWheel();
    }
  }, [config, items]);

  const getSpinIndex = () => {
    return fetch('https://api.client.dawntech.dev/blipid2024/roleta'+ (phone ? `?phone=${phone}` : '')).then(r => r.json()).then((response) => {
      return response.prizeIndex;
    });
  };

  const updateTextAreaItems = (event) => {
    setItems(JSON.parse(event.target.value));
  };

  const updateTextAreaConfig = (event) => {
    setConfig(JSON.parse(event.target.value));
  };

  return (
    <div className="container py-2 px-10 mx-0 min-w-full flex flex-col items-center">
      <div className={`${ debugMode ? 'invisible' : ''} w-full h-full absolute top-0 left-0`}>
        {wheelComponent}
      </div>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center"
        style={{ zIndex: 11 }}
        onClick={async () => wheel.spinToItem(await getSpinIndex(), 4000, true, 5)}
      >
        Girar!
      </button>

      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full absolute left-0"
        style={{ zIndex: 11 }}
        onClick={() => setDebugMode(!debugMode)}
      >
        Items
      </button>

      {
        debugMode ? (
          <div className="container py-5">
            <textarea
              className="w-full block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={JSON.stringify(items, null, 4)}
              rows={25}
              onChange={(event) => updateTextAreaItems(event)}
            />
            <textarea
              className="w-full block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={JSON.stringify(config, null, 4)}
              rows={25}
              onChange={(event) => updateTextAreaConfig(event)}
            />
          </div>
        ) : ''
      }

    </div>
  );
};

export default Roleta;

const jobs = {
  csanalyst: {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://assets.dawntech.dev/content/csdwnt.png',
    bannerBottom: '',
    language: 'pt'
  },
  chatbotdev: {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://cdn.pixabay.com/photo/2019/01/16/20/52/chatbot-3936760_1280.jpg',
    bannerBottom: '',
    language: 'pt'
  },
  uxwriter: {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    bannerBottom: '',
    language: 'pt'
  },
  swengineer: {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    bannerBottom: '',
    language: 'en'
  },
};

export default jobs;

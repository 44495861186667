import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
//import ListItemText from '@mui/material/ListItemText';

import TopImageSection from '../../components/TopImageSection';
import RoomIcon from '@mui/icons-material/Room';

import jobs from 'constants/jobs';
import FlagIcon from '../../svg/flags';

const Subheader = (text) => (
  <>
    <RoomIcon />
    {text}
  </>
);

function JobView(props) {
  const { t } = useTranslation();

  const job = props.match.params.job;
  if (jobs[job] === undefined) {
    return <Redirect from='*' to='/' />;
  }

  return (
    <>
      <TopImageSection
        imageURI={jobs[job].banner}
        padding={25}
        opacity={0.1}
      ></TopImageSection>

      <Container maxWidth="xl">
        <Box
          component={Card}
          mt={-30}
          mb={5}
          pt={5}
          borderRadius={5}
          sx={{
            position: 'relative',
            zIndex: 4
          }}
        >
          <CardHeader 
            title={t(`jobs.${job}.title`)} titleTypographyProps={{ variant: 'h3', fontWeight: 600}}
            sx={{ textAlign: 'center' }}
          ></CardHeader>

          <CardContent>
            <Divider spacing={1}>
              <Chip label={Subheader(t(`jobs.${job}.location`))} sx={{ m: 1 }}/>
              <Chip label={<>
                <b>{t('jobs_view.language')}:</b> <FlagIcon flag={jobs[job].language}></FlagIcon>
              </>} sx={{ m: 2 }} />
            </Divider>
            <Box mx={4} my={2}>
              <Typography variant="h4" fontWeight="700" gutterBottom > 
                {t('jobs_view.description')}
              </Typography>
              <Typography variant="subtitle1">
                {t(`jobs.${job}.description`)}
              </Typography>
            </Box>

            <Box mx={4} my={2}>
              <Typography variant="h4" fontWeight="700">
                {t('jobs_view.requirements')}
              </Typography>

              <List dense style={{'fontSize': '20px'}}>
                {t(`jobs.${job}.requirements`, { returnObjects: true }).map((text,i) => 
                  <ListItem key={i} >
                    <Typography variant="subtitle1">
                      {'- ' + text}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Box>
            
            <Box mx={4} my={2}>
              <Typography variant="h4" fontWeight="700">
                {t('jobs_view.benefits')}
              </Typography>

              <List dense>
                {t(`jobs.${job}.benefits`, { returnObjects: true }).map((text,i) => 
                  <ListItem key={i} >
                    <Typography variant="subtitle1">
                      {'- ' + text}
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Box>

            <Box mx={4} my={2}>
              <Typography variant="h5">
                {t('jobs_view.sendcv')} <b>{t('jobs_view.applicant')} - {t(`jobs.${job}.title`)}</b> {t('jobs_view.to')} 
                <a href={'mailto:jobs@dawntech.dev?subject=' + t('jobs_view.applicant') + ' - ' + t(`jobs.${job}.title`)} target="_blank" rel="noopener noreferrer"> jobs@dawntech.dev</a>.
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </Container>
    </>
  );
}

export default JobView;
